import React from "react";
import Footer from "src/components/Footer_es";

class Page extends React.Component {
  render() {
    return (
      <div>
        
        
        <div id="fb-root" />
        <script
          dangerouslySetInnerHTML={{
            __html:
              "(function(d, s, id) {\nvar js, fjs = d.getElementsByTagName(s)[0];\nif (d.getElementById(id)) return;\njs = d.createElement(s); js.id = id;\njs.src = \"//connect.facebook.net/es_ES/sdk.js#xfbml=1&version=v2.6&appId=607297119325946\";\nfjs.parentNode.insertBefore(js, fjs);\n}(document, 'script', 'facebook-jssdk'));\n"
          }}
        />
        <div id="disclosureText">
        </div>
        <div id="page" className="pageProducts">
          <div id="limit">
            <header id="header">
              <div className="mobileHeaderContainer clearfix">
                <a href="/es/" className="logo">
                  Piz Buin
                </a>
                <button
                  id="btnShowMenu"
                  className="collapse-btn collapse-short-btn right"
                  data-target=".collapse-menu"
                  data-toggle="collapse"
                  type="button"
                />
              </div>
              <nav id="menu" className="collapse-menu collapse-short-menu">
                <ul className="nav-header">
                  <li className="nav-path-selected first has-sub-nav">
                    <a className="es_header" href="/es/nuestros-productos/" target="_self">
                      Nuestros Productos
                    </a>
                    <ul>
                      <li>
                        <a href="/es/nuestros-productos/hydro-infusion/">
                          Hydro Infusion
                        </a>
                      </li>
                      {/* <li>
                        <a href="/es/nuestros-productos/active-protect/">
                          Active &amp; Protect
                        </a>
                      </li> */}
                      <li>
                        <a href="/es/nuestros-productos/moisturising/">
                          Moisturising
                        </a>
                      </li>
                      <li>
                        <a href="/es/nuestros-productos/tan-protect/">
                          Tan &amp; Protect
                        </a>
                      </li>
                      {/* <li>
                        <a href="/es/nuestros-productos/instantglow/">
                          Instant Glow
                        </a>
                      </li> */}
                      <li>
                        <a href="/es/nuestros-productos/allergy/">Allergy</a>
                      </li>
                      <li>
                        <a href="/es/nuestros-productos/mountain/">Mountain</a>
                      </li>
                      <li>
                        <a href="/es/nuestros-productos/after-sun/">
                          After Sun
                        </a>
                      </li>
                      <li>
                        <a href="/es/nuestros-productos/galeria/">Galeria</a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a className="es_header" href="/es/nuestra-historia/" target="_self">
                      Nuestra historia
                    </a>
                  </li>
                  <li>
                    <a className="es__header" href="/es/conozca-el-sol/" target="_self">
                      Conozca el sol
                    </a>
                  </li>
                   {/*<li>
                    <a className="es_header" href="/es/donde-has-estado/" target="_self">
                      Dónde has estado 2019
                    </a>
                  </li>*/}
                </ul>
                <ul className="countryNav">
                  <li
                    className="collapsible has-sub-nav"
                    style={{
                      "list-style": "none"
                    }}
                  >
                    <a className="countries" href="#">
                      Países
                    </a>
                    <ul className="subMenu">
                      <li>
                        <a href="/en/our-products/moisturising/">INT</a>
                      </li>
                      <li>
                        <a href="/uk/our-products/moisturising/">UK</a>
                      </li>
                      <li>
                        <a href="/es/nuestros-productos/moisturising/">ES</a>
                      </li>
                      <li>
                        <a href="/pt/nossos-produtos/moisturising/">PT</a>
                      </li>
                      <li>
                        <a href="/it/prodotti/moisturising/">IT</a>
                      </li>
                      <li>
                        <a href="/gr/our-products/moisturising/">GR</a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </nav>
              <div id="menuExtra" />
            </header>
            <section id="desktop-hero-wrapper" className="product-hero">
              <div id="bgImage">
                <div className="areaInner">
                  <img
                    border="0"
                    className="ccm-image-block"
                    alt
                    src="/assets/images/Banner-Moisturising-ProductPage.png"
                    data-mobile="/assets/images/Banners_Mobile_Moisturising.png"
                  />
                </div>
              </div>
            </section>
            <section id="content">
              <div className="products-page innerContent clearfix">
                <div className="products-page__intro">
                  <div className="container">
                    <h1>
                      PIZ BUIN<sup>®</sup> MOISTURISING
                    </h1>
                    <div className="products-page__intro__description">
                      <p>
                        <strong>Protección e hidratación intensa</strong>
                        <br />
                        Si ama el sol y quiere cuidar su piel, nuestra gama PIZ
                        BUIN<sup>®</sup> MOISTURISING es lo que necesita. Los
                        avanzados filtros UVA/UVB ayudan a proteger la piel del
                        sol y, combinado con un potente anti-oxidante*, ayudan a
                        prevenir su envejecimiento prematuro provocado por la
                        exposición solar. Complejo hidratante especial que
                        proporciona una intensa hidratación para mantener la
                        piel hidratada durante el proceso de bronceado.
                      </p>
                      <p className="footNote">*Excepto stick labial</p>
                    </div>
                  </div>
                </div>
                <div className="products-page__list container clearfix">
                  <div className="products-page__product clearfix">
                    <a name="loci-n-solar-hidratante" />
                    <div className="products-page__product__image">
                      <img src="/assets/images/2019-moisturising-sun-lotion-50spf-200ml.jpg" />
                    </div>
                    <div className="products-page__product__details">
                      <h3>LOCIÓN SOLAR HIDRATANTE</h3>
                      <div className="products-page__product__details__benefits">
                        <h4>BENEFICIOS</h4>
                        <p>
                          La fórmula no grasa y no pegajosa de PIZ BUIN
                          <sup>®</sup> MOISTURISING Loción Solar Hidratante
                          hidrata intensamente su piel mientras se broncea,
                          dejándola tersa y suave como la seda todo el día. PIZ
                          BUIN<sup>®</sup> MOISTURISING Loción Solar Hidratante
                          es resistente a la transpiración y al agua.
                        </p>
                      </div>
                      <div className="products-page__product__details__factors">
                        <h4>Disponible en</h4>
                        <span className="factor factor50" />
                      </div>
                      <div className="products-page__product__details__directions">
                        <h4>APLICACIÓN</h4>
                        <p>
                          Aplicar de forma generosa y uniforme antes de la
                          exposición solar. Utilizar una cantidad insuficiente
                          reduce significativamente el nivel de protección.
                          Reaplicar con frecuencia, especialmente después de
                          transpirar, nadar o secarse con una toalla. Evitar el
                          sol de mediodía y no permanecer mucho tiempo al sol,
                          incluso cuando se use protección solar. Mantener
                          alejados a los bebés y a los niños de la luz directa
                          del sol.
                        </p>
                        <h2>Disponible en formato</h2>
                        <p>200 ml</p>
                      </div>
                      <div className="products-page__product__details__ingredients">
                        <ul className="ingredientsList">
                          <li>
                            <a href="#" className="toggle">
                              INGREDIENTES Y TECNOLOGÍA{" "}
                              <span className="toggle__plus">+</span>
                            </a>
                            <ul>
                              <li>
                                EL AVANZADO SISTEMA DE FILTROS SOLARES UVA/UVB
                                DE PIZ BUIN<sup>®</sup>
                                <div className="textBlock bottom">
                                  <p>
                                    Como especialistas en cuidado solar usamos
                                    tecnologías innovadoras para asegurarnos de
                                    que, siempre que tome el sol, consigue el
                                    equilibrio perfecto entre bronceado y
                                    protección.
                                  </p>
                                  <p>
                                    Cada avanzado sistema de filtros solares
                                    UVA/UVB de PIZ BUIN<sup>®</sup> contiene una
                                    innovadora tecnología de protección solar
                                    desarrollada por Kenvue.
                                    Ofrece protección UVA/UVB fotoestable de
                                    amplio espectro y contribuye a que el
                                    protector solar de PIZ BUIN<sup>®</sup>{" "}
                                    cumpla con la normativa europea más
                                    reciente.
                                  </p>
                                  <p>
                                    Todos los avanzados sistemas de filtros
                                    solares UVA/UVB de PIZ BUIN<sup>®</sup>{" "}
                                    ayudan a proteger la piel de la radiación
                                    UVA y UVB.
                                  </p>
                                  <p>
                                    Los rayos UVB, "los rayos que queman", dañan
                                    la superficie de la piel y son la principal
                                    causa de quemaduras solares.
                                  </p>
                                  <p>
                                    Los rayos UVA , "los rayos que envejecen",
                                    penetran más profundamente y dañan el
                                    colágeno y la elastina, provocando un
                                    envejecimiento prematuro de la piel y
                                    cáncer.
                                  </p>
                                  <p>
                                    El avanzado sistema de filtros solares
                                    UVA/UVB de PIZ BUIN<sup>®</sup> contiene
                                    tanto filtros UVA como UVB para proteger la
                                    piel del sol y de los daños a largo plazo, a
                                    la vez que protegen de los graves efectos de
                                    la radiación UVA. Al cubrir la mayor parte
                                    del espectro UVA/UVB, los filtros ofrecen
                                    una amplia protección.
                                  </p>
                                  <div> </div>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/d9ecfac1ba5e16cd4dcd7d6f49dc467c_f49.png" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                FEVERFEW PFE™
                                <div className="textBlock bottom">
                                  <p>
                                    La planta, Feverfews tiene su origen en el
                                    suelo rocoso y abrupto de las montañas de la
                                    península balcánica pero ahora se puede
                                    encontrar en varios lugares de todo el
                                    mundo. La empresa matriz de PIZ BUIN
                                    <sup>®</sup>, Kenvue, ha
                                    patentado el uso de este innovador extracto
                                    para el cuidado de la piel, aplicando un
                                    nuevo proceso de extracción que preserva los
                                    ingredientes activos, eliminando los
                                    componentes no deseados que podrían provocar
                                    alergias.
                                  </p>
                                  <p>
                                    ¿Cómo ayuda la FEVERVEW PFE<sup>™</sup> a su
                                    piel?
                                  </p>
                                  <p>
                                    El extracto de la planta FEVERVEW PFE
                                    <sup>™</sup> es un ingrediente calmante y
                                    antioxidante muy efectivo. Las pruebas in
                                    vitro han demostrado que tiene una actividad
                                    de aceleración antioxidante superior a la de
                                    otros extractos líderes como la salvia, el
                                    té verde, el té negro, la Vitamina C y la
                                    Vitamina E. Ayuda a aliviar el
                                    enrojecimiento de la piel, repara el ADN de
                                    las células dañadas por los rayos UV y
                                    refuerza la resistencia al sol* de las
                                    células de la piel. Su eficacia ha sido
                                    probada durante varios años de
                                    investigaciones exhaustivas realizadas por
                                    la empresa matriz de la marca PIZ BUIN
                                    <sup>®</sup>, Kenvue, con
                                    diversas patentes que cubren su aplicación
                                    en el campo de la cosmética y el cuidado de
                                    la piel.
                                  </p>
                                  <p className="footNote">
                                    Fuente: Parthenolide-depleted Feverfew
                                    (Tanacetum parthenium) protege la piel de la
                                    radiación UV y la agresión externa, Arch
                                    Dermatol Res. (2008) 300:69-80
                                  </p>
                                  <p>Fig. 1</p>
                                  <p>
                                    FEVERVEW PFE<sup>™</sup> ayuda a proteger
                                    contra las alteraciones de las células,
                                    disminuye los eritemas provocados por los
                                    rayos UVB y contribuye a reparar las células
                                    dañadas por los rayos UV.
                                  </p>
                                  <p>
                                    Hasta un 60% de mejora en el enrojecimiento
                                    y los eritemas provocados por los rayos UVB.
                                  </p>
                                  <p className="footNote">
                                    Fuente: publicación de Kenvue
                                    en Arch Dermatol Res Feb 2008; 300 (2)
                                    69-80, estudio doble ciego aleatorio
                                    controlado con placebo en 12 voluntarios.
                                  </p>
                                  <p className="footNote">Fig. 2</p>
                                  <p>
                                    FEVERVEW PFE<sup>™</sup> ayuda a proteger
                                    contra las quemaduras provocadas por el sol,
                                    estimulando los procesos naturales que
                                    ayudan a mantener la integridad de las
                                    células de la piel y a reforzar su
                                    resistencia al sol.
                                  </p>
                                  <p className="footNote">
                                    Fuente: Kenvue R&amp;D,
                                    Método: aplicación de radiaciones UV en
                                    cultivos de piel humana y evaluación de las
                                    células dañadas por el sol. *Pruebas in
                                    vitro
                                  </p>
                                  <div> </div>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/2ce75fafd970340ab850448b9ac05100_f263.png" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                GLICERINA
                                <div className="textBlock bottom">
                                  <p>
                                    La glicerina atrae y retiene el agua en la
                                    piel para ayudar a mantener un buen nivel de
                                    hidratación.
                                  </p>
                                  <div> </div>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/617fb948ebbaeb31693807e5a660cfbb_f43.png" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="products-page__product clearfix">
                    <a name="stick-labial" />
                    <div className="products-page__product__image">
                      <img src="/assets/images/2019-moisturising-sun-lipstick-aloe-vera.jpg" />
                    </div>
                    <div className="products-page__product__details">
                      <h3>STICK LABIAL</h3>
                      <div className="products-page__product__details__benefits">
                        <h4>BENEFICIOS</h4>
                        <p>
                          PIZ BUIN<sup>®</sup> MOISTURISING Stick Labial protege
                          sus labios de los efectos nocivos de la exposición a
                          los rayos UVA/UVB a la vez que les proporciona una
                          intensa hidratación.
                        </p>
                        <div className="products-page__product__details__benefits__hidden">
                          <p>
                            PIZ BUIN<sup>®</sup> MOISTURISING Stick Labial es
                            resistente al agua.
                          </p>
                        </div>
                        <button className="products-page__product__details__benefits__more">
                          Lee más
                        </button>
                      </div>
                      <div className="products-page__product__details__factors">
                        <h4>Disponible en</h4>
                        <span className="factor factor30a" />
                      </div>
                      <div className="products-page__product__details__directions">
                        <h4>APLICACIÓN</h4>
                        <p>
                          Aplicar de forma generosa y uniforme antes de la
                          exposición solar. Utilizar una cantidad insuficiente
                          reduce significativamente el nivel de protección.
                          Reaplicar con frecuencia, especialmente después de
                          transpirar. Evitar el sol de mediodía y no permanecer
                          mucho tiempo al sol, incluso cuando se use protección
                          solar. Mantener alejados a los bebés y a los niños de
                          la luz directa del sol.
                        </p>
                      </div>
                      <div className="products-page__product__details__ingredients">
                        <ul className="ingredientsList">
                          <li>
                            <a href="#" className="toggle">
                              INGREDIENTES Y TECNOLOGÍA{" "}
                              <span className="toggle__plus">+</span>
                            </a>
                            <ul>
                              <li>
                                EL AVANZADO SISTEMA DE FILTROS SOLARES UVA/UVB
                                DE PIZ BUIN<sup>®</sup>
                                <div className="textBlock bottom">
                                  <p>
                                    Como especialistas en cuidado solar usamos
                                    tecnologías innovadoras para asegurarnos de
                                    que, siempre que tome el sol, consigue el
                                    equilibrio perfecto entre bronceado y
                                    protección.
                                  </p>
                                  <p>
                                    Cada avanzado sistema de filtros solares
                                    UVA/UVB de PIZ BUIN<sup>®</sup> contiene una
                                    innovadora tecnología de protección solar
                                    desarrollada por Kenvue.
                                    Ofrece protección UVA/UVB fotoestable de
                                    amplio espectro y contribuye a que el
                                    protector solar de PIZ BUIN<sup>®</sup>{" "}
                                    cumpla con la normativa europea más
                                    reciente.
                                  </p>
                                  <p>
                                    Todos los avanzados sistemas de filtros
                                    solares UVA/UVB de PIZ BUIN<sup>®</sup>{" "}
                                    ayudan a proteger la piel de la radiación
                                    UVA y UVB.
                                  </p>
                                  <p>
                                    Los rayos UVB, "los rayos que queman", dañan
                                    la superficie de la piel y son la principal
                                    causa de quemaduras solares.
                                  </p>
                                  <p>
                                    Los rayos UVA , "los rayos que envejecen",
                                    penetran más profundamente y dañan el
                                    colágeno y la elastina, provocando un
                                    envejecimiento prematuro de la piel y
                                    cáncer.
                                  </p>
                                  <p>
                                    El avanzado sistema de filtros solares
                                    UVA/UVB de PIZ BUIN<sup>®</sup> contiene
                                    tanto filtros UVA como UVB para proteger la
                                    piel del sol y de los daños a largo plazo, a
                                    la vez que protegen de los graves efectos de
                                    la radiación UVA. Al cubrir la mayor parte
                                    del espectro UVA/UVB, los filtros ofrecen
                                    una amplia protección.
                                  </p>
                                  <div> </div>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/d9ecfac1ba5e16cd4dcd7d6f49dc467c_f49.png" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                ALOE VERA
                                <div className="textBlock bottom">
                                  <p>
                                    ¿Por qué es tan beneficioso el Aloe Vera?
                                  </p>
                                  <p>
                                    El Aloe Vera es un agente hidratante natural
                                    con potentes propiedades calmantes.{" "}
                                  </p>
                                  <p>
                                    <em>
                                      El Aloe Vera está presente en el producto
                                      PIZ BUIN<sup>®</sup> Moisturizing Stick
                                      Labial con Aloe Vera
                                    </em>
                                  </p>
                                  <p>
                                    <em>
                                      El Aloe Vera está presente en el producto
                                      PIZ BUIN<sup>®</sup> After Sun Loción
                                      Hidratante, Calmante y Refrescante
                                    </em>
                                  </p>
                                  <div> </div>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/006e44dceb4947a77acdd3948503af8d_f37.jpg" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                VITAMINA E
                                <div className="textBlock bottom">
                                  <p>
                                    La Vitamina E* es un potente antioxidante
                                    natural que ayuda a proteger de los
                                    radicales libres que dañan las células de la
                                    piel y provocan su envejecimiento prematuro.
                                  </p>
                                  <p>*In vitro </p>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/d5cb67f17c9a6484836ce66200118918_f40.png" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="products-page__product clearfix">
                    <a name="spray-solar-hidratante" />
                    {/* <div className="products-page__product__image">
                      <img src="/assets/images/2019-moisturising-ultra-light-sun-spray-30spf-200ml.jpg" />
                    </div> */}
                     {/* <div className="products-page__product__details">
                      <h3>SPRAY SOLAR HIDRATANTE</h3>
                      <div className="products-page__product__details__benefits">
                        <h4>BENEFICIOS</h4>
                        <p>
                          Este fluido combina una efectiva e inmediata
                          protección con avanzados filtros UVA/UVB con una
                          formula ultra ligera que se seca instantáneamente. El
                          fluido hidratante se extiende fácilmente sobre la piel
                          dejando una sensación ligera y seca . Esta fórmula no
                          grasa ni pegajosa se absorbe al instante sin dejar
                          manchas blancas, para una piel sedosa y suave.
                        </p>
                        <div className="products-page__product__details__benefits__hidden">
                          <p>
                            Enriquecida con un derivado de Vitamina E
                            anti-oxidante* , ayuda a prevenir el envejecimiento
                            prematuro de la piel. Resistente al agua y al sudor.
                          </p>
                          <p>
                            Pedimos a los consumidores su opinión acerca de PIZ
                            BUIN<sup>®</sup> ULTRA LIGHT Dry Touch**:
                          </p>
                          <p>
                            <strong className="orange">100%</strong>{" "}
                            experimentaron:
                          </p>
                          <ul>
                            <li>Absorción inmediata</li>
                            <li>Textura ligera</li>
                            <li>Textura no grasa</li>
                          </ul>
                          <p>
                            <strong className="orange">95%</strong>{" "}
                            experimentaron:
                          </p>
                          <ul>
                            <li>Hidratación durante todo el día</li>
                          </ul>
                        </div>
                        <button className="products-page__product__details__benefits__more">
                          Lee más
                        </button>
                      </div>
                      <div className="products-page__product__details__factors">
                        <h4>Disponible en</h4>
                        <span className="factor factor15" />
                        <span className="factor factor30" />
                      </div>
                      <div className="products-page__product__details__directions">
                        <h4>APLICACIÓN</h4>
                        <p>
                          Aplicar de forma generosa y uniforme antes de la
                          exposición solar. Utilizar una cantidad insuficiente
                          reduce significativamente el nivel de protección.
                          Reaplicar con frecuencia, especialmente después de
                          transpirar, nadar o secarse con una toalla. Evitar el
                          sol de mediodía y no permanecer mucho tiempo al sol,
                          incluso cuando se use protección solar. Mantener
                          alejados a los bebés y a los niños de la luz directa
                          del sol.
                        </p>
                        <p className="footNote">* In vitro test</p>
                        <p className="footNote">
                          &nbsp;&nbsp;** Resultados de autoevaluación obtenidos con el Spray
                          Solar SPF 30, 21 personas, al menos 1 semana de
                          aplicación diaria.
                        </p>
                      </div>
                      <div className="products-page__product__details__ingredients">
                        <ul className="ingredientsList">
                          <li>
                            <a href="#" className="toggle">
                              INGREDIENTES Y TECNOLOGÍA{" "}
                              <span className="toggle__plus">+</span>
                            </a>
                            <ul>
                              <li>
                                EL AVANZADO SISTEMA DE FILTROS SOLARES UVA/UVB
                                DE PIZ BUIN<sup>®</sup>
                                <div className="textBlock bottom">
                                  <p>
                                    Como especialistas en cuidado solar usamos
                                    tecnologías innovadoras para asegurarnos de
                                    que, siempre que tome el sol, consigue el
                                    equilibrio perfecto entre bronceado y
                                    protección.
                                  </p>
                                  <p>
                                    Cada avanzado sistema de filtros solares
                                    UVA/UVB de PIZ BUIN<sup>®</sup> contiene una
                                    innovadora tecnología de protección solar
                                    desarrollada por Kenvue.
                                    Ofrece protección UVA/UVB fotoestable de
                                    amplio espectro y contribuye a que el
                                    protector solar de PIZ BUIN<sup>®</sup>{" "}
                                    cumpla con la normativa europea más
                                    reciente.
                                  </p>
                                  <p>
                                    Todos los avanzados sistemas de filtros
                                    solares UVA/UVB de PIZ BUIN<sup>®</sup>{" "}
                                    ayudan a proteger la piel de la radiación
                                    UVA y UVB.
                                  </p>
                                  <p>
                                    Los rayos UVB, "los rayos que queman", dañan
                                    la superficie de la piel y son la principal
                                    causa de quemaduras solares.
                                  </p>
                                  <p>
                                    Los rayos UVA , "los rayos que envejecen",
                                    penetran más profundamente y dañan el
                                    colágeno y la elastina, provocando un
                                    envejecimiento prematuro de la piel y
                                    cáncer.
                                  </p>
                                  <p>
                                    El avanzado sistema de filtros solares
                                    UVA/UVB de PIZ BUIN<sup>®</sup> contiene
                                    tanto filtros UVA como UVB para proteger la
                                    piel del sol y de los daños a largo plazo, a
                                    la vez que protegen de los graves efectos de
                                    la radiación UVA. Al cubrir la mayor parte
                                    del espectro UVA/UVB, los filtros ofrecen
                                    una amplia protección.
                                  </p>
                                  <div> </div>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/d9ecfac1ba5e16cd4dcd7d6f49dc467c_f49.png" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                FEVERFEW PFE™
                                <div className="textBlock bottom">
                                  <p>
                                    La planta, Feverfews tiene su origen en el
                                    suelo rocoso y abrupto de las montañas de la
                                    península balcánica pero ahora se puede
                                    encontrar en varios lugares de todo el
                                    mundo. La empresa matriz de PIZ BUIN
                                    <sup>®</sup>, Kenvue, ha
                                    patentado el uso de este innovador extracto
                                    para el cuidado de la piel, aplicando un
                                    nuevo proceso de extracción que preserva los
                                    ingredientes activos, eliminando los
                                    componentes no deseados que podrían provocar
                                    alergias.
                                  </p>
                                  <p>
                                    ¿Cómo ayuda la FEVERVEW PFE<sup>™</sup> a su
                                    piel?
                                  </p>
                                  <p>
                                    El extracto de la planta FEVERVEW PFE
                                    <sup>™</sup> es un ingrediente calmante y
                                    antioxidante muy efectivo. Las pruebas in
                                    vitro han demostrado que tiene una actividad
                                    de aceleración antioxidante superior a la de
                                    otros extractos líderes como la salvia, el
                                    té verde, el té negro, la Vitamina C y la
                                    Vitamina E. Ayuda a aliviar el
                                    enrojecimiento de la piel, repara el ADN de
                                    las células dañadas por los rayos UV y
                                    refuerza la resistencia al sol* de las
                                    células de la piel. Su eficacia ha sido
                                    probada durante varios años de
                                    investigaciones exhaustivas realizadas por
                                    la empresa matriz de la marca PIZ BUIN
                                    <sup>®</sup>, Kenvue, con
                                    diversas patentes que cubren su aplicación
                                    en el campo de la cosmética y el cuidado de
                                    la piel.
                                  </p>
                                  <p className="footNote">
                                    Fuente: Parthenolide-depleted Feverfew
                                    (Tanacetum parthenium) protege la piel de la
                                    radiación UV y la agresión externa, Arch
                                    Dermatol Res. (2008) 300:69-80
                                  </p>
                                  <p>Fig. 1</p>
                                  <p>
                                    FEVERVEW PFE<sup>™</sup> ayuda a proteger
                                    contra las alteraciones de las células,
                                    disminuye los eritemas provocados por los
                                    rayos UVB y contribuye a reparar las células
                                    dañadas por los rayos UV.
                                  </p>
                                  <p>
                                    Hasta un 60% de mejora en el enrojecimiento
                                    y los eritemas provocados por los rayos UVB.
                                  </p>
                                  <p className="footNote">
                                    Fuente: publicación de Kenvue
                                    en Arch Dermatol Res Feb 2008; 300 (2)
                                    69-80, estudio doble ciego aleatorio
                                    controlado con placebo en 12 voluntarios.
                                  </p>
                                  <p className="footNote">Fig. 2</p>
                                  <p>
                                    FEVERVEW PFE<sup>™</sup> ayuda a proteger
                                    contra las quemaduras provocadas por el sol,
                                    estimulando los procesos naturales que
                                    ayudan a mantener la integridad de las
                                    células de la piel y a reforzar su
                                    resistencia al sol.
                                  </p>
                                  <p className="footNote">
                                    Fuente: Kenvue R&amp;D,
                                    Método: aplicación de radiaciones UV en
                                    cultivos de piel humana y evaluación de las
                                    células dañadas por el sol. *Pruebas in
                                    vitro
                                  </p>
                                  <div> </div>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/2ce75fafd970340ab850448b9ac05100_f263.png" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                GLICERINA
                                <div className="textBlock bottom">
                                  <p>
                                    La glicerina atrae y retiene el agua en la
                                    piel para ayudar a mantener un buen nivel de
                                    hidratación.
                                  </p>
                                  <div> </div>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/617fb948ebbaeb31693807e5a660cfbb_f43.png" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </div>  */}
                  </div>
                </div>
              </div>
            </section>
            <nav className="platform-links">
              <div className="container">
                <ul>
                  <li>
                    <a
                      className="first"
                      href="/es/nuestros-productos/hydro-infusion/"
                      target="_self"
                    >
                      Hydro Infusion
                    </a>
                  </li>
                  {/* <li>
                    <a
                      href="/es/nuestros-productos/active-protect/"
                      target="_self"
                    >
                      Active &amp; Protect
                    </a>
                  </li> */}
                  <li>
                    <a
                      className="platform-links__active nav-path-selected"
                      href="/es/nuestros-productos/moisturising/"
                      target="_self"
                    >
                      Moisturising
                    </a>
                  </li>
                  <li>
                    <a
                      href="/es/nuestros-productos/tan-protect/"
                      target="_self"
                    >
                      Tan &amp; Protect
                    </a>
                  </li>
                  {/* <li>
                    <a
                      href="/es/nuestros-productos/instantglow/"
                      target="_self"
                    >
                      Instant Glow
                    </a>
                  </li> */}
                  <li>
                    <a href="/es/nuestros-productos/allergy/" target="_self">
                      Allergy
                    </a>
                  </li>
                  <li>
                    <a href="/es/nuestros-productos/mountain/" target="_self">
                      Mountain
                    </a>
                  </li>
                  <li>
                    <a href="/es/nuestros-productos/after-sun/" target="_self">
                      After Sun
                    </a>
                  </li>
                  <li>
                    <a href="/es/nuestros-productos/galeria/" target="_self">
                      Galeria
                    </a>
                  </li>
                </ul>
              </div>
            </nav>
            <section id="social">
              <a
                href="https://www.facebook.com/PizBuinEspana"
                target="_blank"
                className="fb"
              >
                &nbsp;
              </a>
              <a
                href="https://instagram.com/pizbuinespana"
                target="_blank"
                className="inst"
              >
                &nbsp;
              </a>
              <a
                href="https://www.youtube.com/channel/UC4UKe2bJ7_abRLvHmWKSzzg"
                target="_blank"
                className="yt"
              >
                &nbsp;
              </a>
            </section>
            <div className="clear">
              <br />
            </div>
            <Footer></Footer>
          </div>
        </div>
        <script src="/assets/js/main.js" />
        <script src="/assets/js/vendor/jquery.fancybox.js" />
        <script src="/assets/js/vendor/jquery.fancybox-media.js" />
        <script src="/assets/js/vendor/iealert.min.js" />
        
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\n$(document).ready(function(){\n$('.products-page__product__details__benefits__more').on('click', function(){\nvar box = $(this).siblings('.products-page__product__details__benefits__hidden');\nif(box.is(':visible')){\nbox.slideUp();\n$(this).text('Lee más');\n} else {\nbox.slideDown();\n$(this).text('Leer menos');\n}\n});\n});\n"
          }}
        />
        
        
        <div className="clear">
          <br />
        </div>
      </div>
    );
  }
}

export default Page;
